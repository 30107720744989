



import Vue from 'vue';
import SignInContainer from '@/container/auth/SignInContainer.vue';

export default Vue.extend({
  name: 'SignInScene',

  components: {
    SignInContainer,
  },
});
