











































































































































import LocalDataService from '@/service/LocalDataServices';
import { auth0Module } from '@/store/viewModule/auth/auth0Module';
import { signInModule } from '@/store/viewModule/auth/signInModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { isMobile } from '@/service/StyleService';
import { gaSetUserId } from '@/service/GoogleAnalyticsService';
import Vue from 'vue';
import {
  MyTeamResponse, UserResponse,
} from 'remonade-api/lib';

interface DataType {
}

export default Vue.extend({
  name: 'SignInContainer',

  computed: {
    showLoader() {
      return commonModule.showLoader;
    },
    email(): string {
      return signInModule.email;
    },
    password(): string {
      return signInModule.password;
    },
    teamId(): string {
      return signInModule.teamId;
    },
    isInputValid(): boolean {
      return signInModule.isInputValid;
    },
    accounts(): MyTeamResponse[] {
      return auth0Module.accounts;
    },
    isMobile(): boolean {
      return isMobile(window);
    },
    showSignUp(): boolean {
      return auth0Module.showSignUp;
    },
  },

  methods: {
    setEmail(email: string) {
      signInModule.setEmail(email);
    },
    setPassword(password: string) {
      signInModule.setPassword(password);
    },
    setTeamId(teamId: string) {
      signInModule.setTeamId(teamId);
    },
    setShowSignUp(show: boolean) {
      auth0Module.setShowSignUp(show);
    },
    async signIn() {
      commonModule.setLoader(true);
      const user = await signInModule.signIn();
      commonModule.setLoader(false);

      if (user) {
        this.handleRedirect(user);
        return;
      }
      // error handling
      alert(this.$t('errorSignin'));
    },
    socialLogin() {
      auth0Module.signinProcess();
    },
    async selectTeam(account: MyTeamResponse) {
      LocalDataService.setTeamId(account.teamId);
      LocalDataService.setUserId(account.user.userId);
      this.handleRedirect(account.user);
    },
    async handleRedirect(user: UserResponse) {
      const teamId = user.teamId;
      LocalDataService.setUser(user);
      LocalDataService.setTeamId(user.teamId);
      commonModule.setLoader(false);
      gaSetUserId(user.userId, user.userType);
      const authType = LocalDataService.getAuthType();
      if (user.isPasswordChangeRequired && authType === 'USER_POOL') {
        this.$router.push({
          name: 'change_password',
          query: {
            teamId: user.teamId,
            email: user.email,
          },
        });
      } else if (user.isFirstLogin) {
        // TODO: ここに入ってきたらOnboadingに画面遷移
        if (user.isFirstCreator) {
          this.$router.push({ name: 'onboarding', params: { teamId } });
        } else {
          if (!this.isMobile) {
            this.$router.push({ name: 'today'});
          } else {
            this.$router.push({ name: 'mobile'});
          }
        }
      } else {
        if (!this.isMobile) {
          this.$router.push({ name: 'today'});
        } else {
          this.$router.push({ name: 'mobile'});
        }
      }
    },
  },

  async mounted() {
    if (
      location.href.indexOf('eAuthorized') >= 0
    ) {
      // EDIT THIS BLOCK ONLY IN ELECTRON BRANCH
      commonModule.setLoader(true);
      await auth0Module.listTeams();
      commonModule.setLoader(false);
      return;
    }

    const isSessionValid = await commonModule.checkSession();

    if (isSessionValid) {
      const authType = LocalDataService.getAuthType();
      if (authType === 'USER_POOL') {
        const localUser = LocalDataService.getUser();
        if (localUser) {
          if (!this.isMobile) {
            this.$router.push({ name: 'today'});
          } else {
            this.$router.push({ name: 'mobile'});
          }
        }
      } else if (authType ===  'OPENID_CONNECT') {
        auth0Module.getAuth0Token().then((token) => {
          if (token) {
            const localUser = LocalDataService.getUser();
            if (localUser) {
              if (!this.isMobile) {
                this.$router.push({ name: 'today'});
              } else {
                this.$router.push({ name: 'mobile'});
              }
            }
          } else {
            throw(null);
          }
        });
      }
    }

    this.setShowSignUp(false);

    await auth0Module.initClient();

    if (
      this.$route.query &&
      this.$route.query.code &&
      this.$route.query.state
    ) {
      commonModule.setLoader(true);
      await auth0Module.handleAuth0();
      commonModule.setLoader(false);
    }
  },

  watch: {
    showSignUp() {
      if (this.showSignUp) {
        this.$router.push({ name: 'create-team'});
      }
    },
  },
});
