import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import LocalDataService from '@/service/LocalDataServices';

import { signIn } from '@/api/client';

export interface SignInState {}

@Module({ dynamic: true, store, name: 'sign-in', namespaced: true })
class SignInModule extends VuexModule implements SignInState {
  public teamId: string = '';
  public email: string = '';
  public password: string = '';

  public get isInputValid() {
    return this.teamId !== ''
      && this.email !== ''
      && this.password !== '';
  }

  @Action
  public async signIn() {
    const signinResponse = await signIn({
      teamId: this.teamId,
      email: this.email,
      password: this.password,
    });

    if ('error' in signinResponse) {
      return null;
    }

    LocalDataService.setAccessToken(signinResponse.accessToken);
    LocalDataService.setRefreshToken(signinResponse.refreshToken);
    LocalDataService.setIdToken(signinResponse.idToken);

    LocalDataService.setUserId(signinResponse.user.userId);
    LocalDataService.setTeamId(signinResponse.user.teamId);

    return signinResponse.user;
  }

  @Mutation
  public setTeamId(teamId: string) {
    this.teamId = teamId;
  }

  @Mutation
  public setEmail(email: string) {
    this.email = email;
  }

  @Mutation
  public setPassword(password: string) {
    this.password = password;
  }
}

export const signInModule = getModule(SignInModule);
